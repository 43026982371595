<template>
  <div class="modal-ticket-comment">
    <esmp-modal
      v-model="show"
      ref="modal"
      title="Комментарий"
      :width="800"
      ok-text="Ответить"
      cancel-text="Закрыть"
      class-name="modal-ticket-comment"
    >
      <hm-text-area
        placeholder="Ваш комментарий"
        v-model="comment"
      />
      <esmp-upload
        multiple
        :max-size="$options.MAX_SIZE_UPLOAD_FILE"
        :format="fileMimes"
        v-model="files"
      />
      <div slot="footer">
        <esmp-button @click="sendComment">
          Ответить
        </esmp-button>
        <esmp-button view="outline" @click="close">
          Закрыть
        </esmp-button>
      </div>
    </esmp-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import getMimesMixin from '@/mixins/getMimes';
import { MAX_SIZE_UPLOAD_FILE } from '@/constants/uploadFiles';
import HmTextArea from '@/components/hm-form/controls/HmTextArea.vue';

export default {
  name: 'ModalTicketComment',
  MAX_SIZE_UPLOAD_FILE,
  mixins: [getMimesMixin],
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    sourceSystem: {
      type: String,
      required: true,
    },
    ticketId: {
      type: [String, Number],
      required: true,
    },
  },
  components: {
    HmTextArea,
  },
  data() {
    return {
      files: [],
      comment: '',
    };
  },
  computed: {
    show: {
      get() {
        return this.isShow;
      },
      set(newValue) {
        this.$emit('hide-modal', { modalName: this.$options.name, newValue });
      },
    },
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    async sendComment() {
      if (this.comment) {
        this.setLoading({ key: 'app', value: true });
        const data = new FormData();
        data.append('source', this.sourceSystem);
        this.files.forEach((file) => {
          data.append('attachmentList', file, file.name);
        });
        data.append('comment', this.comment);

        this.$API.ticket.addCommentToTicket(this.ticketId, data)
          .then(() => {
            this.comment = '';
            this.files = [];
            this.$EsmpNotify.$show('Комментарий успешно добавлен', 'success');
          })
          .catch((err) => err)
          .finally(() => {
            this.setLoading({ key: 'app', value: false });
            this.close();
          });
      } else {
        this.$EsmpNotify.$show('Введите комментарий или удалите недопустимые файлы', 'error');
      }
    },
    close() {
      this.show = false;
    },
  },
  watch: {
    show(val) {
      if (val) {
        this.getMimes();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.esmp-modal-body {
  font-size: 16px;
}
</style>
