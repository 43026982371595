import { SOURCE_SYSTEM } from '@/constants/network';

export default {
  name: 'GetMimesMixin',
  data() {
    return {
      fileMimes: [],
    };
  },
  methods: {
    getMimes() {
      if (!this.fileMimes || !this.fileMimes.length) {
        const params = {
          source: this.sourceSystem ? this.sourceSystem : SOURCE_SYSTEM,
        };
        this.$API.ticket.getAttachmentExtensionsWhiteList(params)
          .then(({ data }) => {
            if (data) this.fileMimes = data;
          })
          .catch((err) => err);
      }
    },
  },
};
